
.home {
    margin-top: -20px;
    position: relative;
    width: 100%;
    height: 100%;    
    background: #E7ACE9;
}

.landingPad {
    position: relative;
    top: 20px;
    margin: 20px;
    height: 948px;
    background-color: #B5EAFB;
}

h1 {
    padding: 20px;
    font-style: normal;
    font-weight: bold;    
    color: #000000;
}

.intro {
    padding: 20px;
    background-color: #E7ACE9;
    text-align: center;
    justify-content: center;
    display: inline-block;
}

h3 {
    text-align: center;
    padding-top: 20px;
}

.faq {
    position: relative;
    margin: 20px;
    height: 30%;
    background-color: #B5EAFB;
}

.faqBody {
    padding: 20px;
}

.dropALine {
    text-align: left;
    padding: 20px;
    color: #FFFFFF;
    font-weight: bold;
}

.btn-custom {
    color: #FFFFFF !important;
    background-color: #AC7157 !important;
}

img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    margin: 20px;
}
